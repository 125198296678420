import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import galactoonBg from '../../../../assets/images/icon/galactoonWithBackground.png';
import galactoonNBg from '../../../../assets/images/icon/galactoonNoBackground.png';
import { Col, Container, Row } from 'react-bootstrap';

const Footer = () => {
    const accountList = [
        {
            title: "Wallet Connect",
            link: "/wallet-connect"
        },
        {
            title: "My Profile",
            link: "/profile"
        },
        {
            title: "All Profile",
            link: "/allprofile"
        },
    ]
    const companyList = [
        {
            title: "Contact Us",
            link: "/contactus"
        },
        {
            title: "FAQ",
            link: "/faq"
        },
    ]
    const socialList = [
        {
            icon: "fab fa-twitter",
            link: "https://twitter.com/Galactoon1"
        },
        {
            icon: "fab fa-instagram",
            link: "https://z-p15.www.instagram.com/galactoon/"
        },
        {
            icon: "fab fa-facebook",
            link: "https://www.facebook.com/people/Galactoon/100088111307052/"
        },
        {
            icon: "icon-fl-tik-tok-2",
            link: "#"
        },
        {
            icon: "icon-fl-vt",
            link: "https://discord.com/invite/azR8EQ3B"
        },  
    ]

    const [isVisible, setIsVisible] = useState(false);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    useEffect(() => {
        const toggleVisibility = () => {
            if (window.pageYOffset > 500) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener("scroll", toggleVisibility);

        return () => window.removeEventListener("scroll", toggleVisibility);
    }, []);


    return (
        <div>
            <footer id="footer" className="footer-light-style clearfix bg-style">
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-lg-3 col-md-12 col-12">
                            <div className="widget widget-logo">
                                <div id="site-logo" className="clearfix">
                                    <div id="site-logo-inner">
                                        <Col style={{ width: "300px", alignItems: "center" }} >
                                            <Row style={{ marginBottom:"10px", marginTop:"50px", }}>
                                                <Col md="3"></Col>
                                                <Col md="6">
                                                        <Link to="/" rel="home" className="main-logo" >
                                                            <img className='logo-dark' id="logo_header" src={galactoonBg} alt="Galactoon" />
                                                            <img className='logo-light' id="logo_header" src={galactoonNBg} alt="Galactoon" />
                                                        </Link>
                                                </Col>
                                                <Col md="3"></Col>
                                            </Row>
                                            <Row >
                                                <Container>
                                                    <p className="sub-widget-logo">Are you ready to have fun with us at the point where the fantastic world of the card game and the magical world of Nft meet?</p>
                                                </Container>
                                            </Row>
                                        </Col>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-7 col-12">
                            <div className="widget widget-menu fl-st-3">
                                <h5 className="title-widget">My Account</h5>
                                <ul>
                                    {
                                        accountList.map((item, index) => (
                                            <li key={index}><Link to={item.link}>{item.title}</Link></li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-7 col-12">
                            <div className="widget widget-menu fl-st-3">
                                <h5 className="title-widget">Company</h5>
                                <ul>
                                    {
                                        companyList.map((item, index) => (
                                            <li key={index}><Link to={item.link}>{item.title}</Link></li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-7 col-12">
                            <div className="widget widget-subcribe">
                                <h5 className="title-widget">Follow Us</h5>
                                <div className="widget-social style-1 mg-t32">
                                    <ul>
                                        {
                                            socialList.map((item, index) => (
                                                <li key={index}><a href={item.link}><i className={item.icon}></i></a></li>
                                            ))
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            {
                isVisible &&
                <Link onClick={scrollToTop} to='#' id="scroll-top"></Link>
            }

            <div className="modal fade popup" id="popup_bid" tabIndex={-1} role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <div className="modal-body space-y-20 pd-40">
                            <h3>Place a Bid</h3>
                            <p className="text-center">You must bid at least <span className="price color-popup">4.89 ETH</span>
                            </p>
                            <input type="text" className="form-control"
                                placeholder="00.00 ETH" />
                            <p>Enter quantity. <span className="color-popup">5 available</span>
                            </p>
                            <input type="number" className="form-control" placeholder="1" />
                            <div className="hr"></div>
                            <div className="d-flex justify-content-between">
                                <p> You must bid at least:</p>
                                <p className="text-right price color-popup"> 4.89 ETH </p>
                            </div>
                            <div className="d-flex justify-content-between">
                                <p> Service free:</p>
                                <p className="text-right price color-popup"> 0,89 ETH </p>
                            </div>
                            <div className="d-flex justify-content-between">
                                <p> Total bid amount:</p>
                                <p className="text-right price color-popup"> 4 ETH </p>
                            </div>
                            <Link to="#" className="btn btn-primary" data-toggle="modal" data-target="#popup_bid_success" data-dismiss="modal" aria-label="Close"> Place a bid</Link>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    );
}

export default Footer;
