import { createContext, useEffect, useState } from "react";
import { useMoralis } from "react-moralis";

type Props = {
    children: React.ReactNode,
};

export enum Status {
    Initializing = "Initializing",
    Authenticated = "Authenticated",
    NoAuthentication = "NoAuthentication",
    Error = "Error",
    Ready = "Ready",
}

export type AuthStatus = {
    status:  Status
    message?: string
}

export type Authentication = {
    isAuthenticated: boolean,
    setIsAuthenticated: (value: boolean) => void,
    user: any,
    setUser: (value: any) => void,
    status: AuthStatus,
    setStatus: (value: AuthStatus) => void,
    token?: string,
    setToken: (value?: string) => void,
    authenticate?: string,
    setAuthenticate : (value?: string) => void
}


export const DefaultAuthValues: Authentication = {
    isAuthenticated: false,
    setIsAuthenticated: (value: boolean) => {},
    user: undefined,
    setUser: (value: any) => {},
    status: {status: Status.Initializing},
    setStatus: (value: AuthStatus) => {},
    token: undefined,
    setToken: (value?: string) => {},
    authenticate: undefined,
    setAuthenticate : (value?: string) => {}
}

const AuthContext = createContext<Authentication>(DefaultAuthValues);

export default AuthContext;

export function AuthProvider(props : Props) {
    const moralis = useMoralis();
    
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, setUser] = useState<any>({});
    const [status, setStatus] = useState<AuthStatus>({status: Status.Initializing});
    const [token, setToken] = useState<string | undefined>(undefined);
    const [authenticate, setAuthenticate] = useState<string | undefined>(undefined);

    useEffect(() => {
        // Initialized
        if (!moralis.isInitializing && moralis.isInitialized) {

            // Not authenticated
            if (moralis.user == null) {
                console.log("No moralis user");
                setStatus({status: Status.NoAuthentication});
            }
            // authenticated
            else {
                setUser(moralis.user);
                setToken(moralis.user.getSessionToken());
                setStatus({status: Status.Authenticated});
            }
        }
        // Initializing
        else {
            setStatus({status: Status.Initializing});
        }

    }, [moralis.isInitializing, moralis.isInitialized]);

    return (
        <AuthContext.Provider value={{isAuthenticated, setIsAuthenticated, user, setUser, status, setStatus, token, setToken, authenticate, setAuthenticate}}>
            {props.children}
        </AuthContext.Provider>
    )
}