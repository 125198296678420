import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ethers } from "ethers";
import { Button } from "react-bootstrap";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import img1 from "../../../../assets/images/icon/phantomWalletLogo.png";
import img2 from "../../../../assets/images/icon/metamaskWalletLogo.png";
import { setWallet } from "../../../../store/walletSlice";
import { RootState } from "../../../../store";

export default function WalletConnect() {
	const dispatch = useDispatch();

	const {
		address: walletAddress,
		balance,
		isConnected,
	} = useSelector((state: RootState) => state.wallet);

	const handleMetamaskWallet = async () => {
		try {
			if (window.ethereum) {
				const provider = new ethers.BrowserProvider(window.ethereum);

				await window.ethereum.request({
					method: "eth_requestAccounts",
				});

				const signer = await provider.getSigner();
				const address = await signer.getAddress();

				const balance = await provider.getBalance(address);
				const balanceInEth = ethers.formatEther(balance);

				dispatch(setWallet({ address, balance: balanceInEth }));
			} else {
				console.log("Metamask not detected");
			}
		} catch (error) {
			console.error("Error connecting to Metamask: ", error);
		}
	};

	return (
		<div>
			{/* The Header component will read from Redux state, no props needed */}
			<Header />
			<section className="flat-title-page inner">
				<div className="overlay"></div>
				<div className="themesflat-container">
					<div className="row">
						<div className="col-md-12">
							<div className="page-title-heading mg-bt-12">
								<h1 className="heading text-center">
									Connect Wallet
								</h1>
							</div>
							<div className="breadcrumbs style2">
								<ul>
									<li>
										<Link to="/home">Home</Link>
									</li>
									<li>
										<Link to="#">Pages</Link>
									</li>
									<li>Connect Wallet</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</section>
			<div className="tf-connect-wallet tf-section">
				<div className="themesflat-container">
					<div className="row">
						<div className="col-12">
							<h2 className="tf-title-heading ct style-2 mg-bt-12">
								Connect Your Wallet
							</h2>
						</div>
						<div className="col-md-12" style={{ padding: "20px" }}>
							<div className="sc-box-icon style-2 content-center">
								<div className="sc-box-icon">
									<div className="img">
										<img
											src={img1}
											alt="Phantom Wallet"
											width="100px"
										/>
									</div>
									<h2 className="heading">
										<Button
											onClick={() =>
												console.log("Phantom Login")
											}
											style={{
												width: "150px",
												fontSize: "25px",
											}}
										>
											Phantom
										</Button>
									</h2>
								</div>
							</div>
							<div className="sc-box-icon style-2 content-center">
								<div className="sc-box-icon">
									<div className="img">
										<img
											src={img2}
											alt="Metamask Wallet"
											width="100px"
										/>
									</div>
									<h2 className="heading">
										<Button
											onClick={handleMetamaskWallet}
											style={{
												width: "150px",
												fontSize: "25px",
											}}
										>
											Metamask
										</Button>
									</h2>
								</div>
							</div>

							{/* Show wallet information if connected */}
							{/* {isConnected && (
								<div
									style={{
										marginTop: "20px",
										textAlign: "center",
									}}
								>
									<h3>
										Connected Wallet Address:{" "}
										{walletAddress}
									</h3>
									<h4>Balance: {balance} ETH</h4>
								</div>
							)} */}
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
}
