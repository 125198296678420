import h1 from '../images/tempCards/h1.png'
import h2 from '../images/tempCards/h2.png'
import h3 from '../images/tempCards/h3.png'
import v1 from '../images/tempCards/v1.png'
import v2 from '../images/tempCards/v2.png'
import v3 from '../images/tempCards/v3.png'
import e1 from '../images/tempCards/e1.png'
import e2 from '../images/tempCards/e2.png'

const marketCards = [
    {
        img: h1,
        tags: "human",
        nameAuthor: "Moralabs",
        price: "4.89 ETH",
        priceChange: "$12.246",
        nameCollection: "Human Warrior"
    },
    {
        img: h2,
        tags: "human",
        nameAuthor: "Moralabs",
        price: "4.89 ETH",
        priceChange: "$12.246",
        nameCollection: "Human Mage"
    },
    {
        img: h3,
        tags: "human",
        nameAuthor: "Moralabs",
        price: "4.89 ETH",
        priceChange: "$12.246",
        nameCollection: "Human Archer"
    },
    {
        img: v1,
        tags: "vungi",
        nameAuthor: "Moralabs",
        price: "4.89 ETH",
        priceChange: "$12.246",
        nameCollection: "Vungi Mage"
    },
    {
        img: v2,
        tags: "vungi",
        nameAuthor: "Moralabs",
        price: "4.89 ETH",
        priceChange: "$12.246",
        nameCollection: "Vungi Knigth"
    },
    {
        img: v3,
        tags: "vungi",
        nameAuthor: "Moralabs",
        price: "4.89 ETH",
        priceChange: "$12.246",
        nameCollection: "Vungi Healer"
    },
    {
        img: e1,
        tags: "ecrox",
        nameAuthor: "Moralabs",
        price: "4.89 ETH",
        priceChange: "$12.246",
        nameCollection: "Ecrox Mage"
    },
    {
        img: e2,
        tags: "ecrox",
        nameAuthor: "Moralabs",
        price: "4.89 ETH",
        priceChange: "$12.246",
        nameCollection: "Ecrox Healer"
    },
    
]

export default marketCards;