const widgetSidebarData = [
    {
        id: 1,
        title: "Race",
        content : [
            {
                field: 'Human'
            },
            {
                field: 'Robot'
            },
            {
                field: 'Grooms',
            },
            {
                field: 'Ecrox'
            },
            {
                field: 'Vungi'
            },
            {
                field: 'Support'
            },
        ]
    },
    {
        id: 2,
        title: "Rarity",
        content : [
            {
                field: 'Common'
            },
            {
                field: 'Uncommon'
            },
            {
                field: 'Rare'
            },
            {
                field: 'Epic'
            },
            {
                field: 'Legendary'
            },
        ]
    },
]

export default widgetSidebarData;