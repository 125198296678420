
import { Table } from 'react-bootstrap';
import LeaderBoardModel from '../../../../../business/model/Leaderboard/LeaderBoardModel'

interface Props {
  leaderboard?: LeaderBoardModel
}

function AllLeaderBoard(props: Props) {
  const allLeads = props.leaderboard;
  console.log("allLeads : ", allLeads)
  return (
    <div className="themesflat-container">
      <Table>
        <thead>
          <tr>
            <th style={{ textAlign: "center" }}>Order</th>
            <th style={{ textAlign: "center" }}>Player</th>
            <th style={{ textAlign: "center" }}>Score</th>
          </tr>
        </thead>
        <tbody>
          {allLeads !== null && allLeads !== undefined && allLeads.users.length > 0 ? allLeads.users.map((user: any, index: number) => (
            <tr key={index}>
              <td style={{ textAlign: "center" }}>{user.order}</td>
              <td style={{ textAlign: "center" }}>{user.username}</td>
              <td style={{ textAlign: "center" }}>{user.totalScore}</td>
            </tr>))        
            :
            (<tr>
              <td style={{ textAlign: "center" }}></td>
              <td style={{ textAlign: "center" }}></td>
              <td style={{ textAlign: "center" }}></td>
            </tr>)
          } 
        </tbody>
      </Table>
    </div>
  )
}

export default AllLeaderBoard
