import imgbg1 from '../images/slider/bg_slide_1.png'
import imgbg2 from '../images/slider/bg_slide_1.png'
import humanMage from '../images/slider/human_mage2.png'
import vungiKnigth from '../images/slider/vungi_mage.png'
import galactoonNBg from '../images/icon/galactoonNoBackground.png';
import galactoonBg from '../images/icon/galactoonWithBackground.png';
const heroSliderData = [
    {
        logo1: galactoonNBg,   
        title_1: "Explore, Play",
        title_2: "WIN",
        description: "Discover the most special cards you can add to your collection with this game!",
        class:'center'
    },
    {
        logo1: galactoonNBg,  
        logo2 : galactoonBg, 
        title_1: "Explore, Play",
        title_2: "WIN",
        description: "Discover the most special cards you can add to your collection with this game!",
        img: humanMage,
        imgbg: imgbg1,
        class:'left'
    },
    {
        logo1: galactoonNBg,  
        logo2 : galactoonBg, 
        title_1: "Explore, Play",
        title_2: "WIN",
        description: "Discover the most special cards you can add to your collection with this game!",
        img: vungiKnigth,
        imgbg: imgbg2,
        class:'right'
    },
]

export default heroSliderData;