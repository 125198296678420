import './App.css';
import { Routes, Route } from 'react-router-dom';
import routes from './presentation/view/pages/index';
import { BrowserRouter } from 'react-router-dom'
import ScrollToTop from './ScrollToTop';
import { MoralisProvider } from 'react-moralis';
import { AuthProvider } from './startup/AuthProvider';
import { DependencyProvider } from './startup/DependencyProvider';

function App() {

    return (
        <MoralisProvider appId="bKqt2K46X8pWXLonA7nAwR8OzUxlkkmJ4J1EDxuj" serverUrl="https://gi9gfnbmznup.usemoralis.com:2053/server">
            <AuthProvider>
                <DependencyProvider>
                    <BrowserRouter>
                        <Routes >
                            {
                                routes.map((data: any, index: number) => (
                                    <Route path={data.path} element={data.component} key={index} />
                                ))
                            }
                        </Routes>
                        <ScrollToTop />
                    </BrowserRouter>
                </DependencyProvider>
            </AuthProvider>
        </MoralisProvider>
    );
}

export default App;
