const menus = [
    {
        id: 1,
        name: 'Home',
        links : '/'
    },
    {
        id: 2,
        name: 'Players',
        links: '#',
        namesub: [
            {
                id: 1,
                sub: 'All Players',
                links: '/allplayers' //TODO: add id
            },
            {
                id: 2,
                sub: 'Leader Board',
                links: '/leaderboard' //TODO: add id
            },
        ],
    },
    {
        id: 3,
        name: 'Market',
        links: '/market',
        namesub: [
            {
                id: 1,
                sub: 'All Cards',
                links: '/market' 
            },
            {
                id: 2,
                sub: 'Loot Box',
                links: '/lootbox' 
            },
        ],       
    },
    {
        id: 4,
        name: 'Game',
        links: '#',
        
    },
    {
        id: 5,
        name: 'Profile',
        links: '#',
        namesub: [
            {
                id: 1,
                sub: 'My Profile',
                links: '/profile' //TODO: add id
            },
            {
                id: 2,
                sub: 'Edit Profile',
                links: '/editprofile' //TODO: add id
            },
            {
                id: 3,
                sub: 'Logout',
                links: '/logout'
            },
        ],
    },
]

export default menus;