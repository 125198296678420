import React from 'react';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import { Link } from 'react-router-dom'
import widgetSidebarData from '../../../../assets/fake-data/data-widget-sidebar';
import Explore from '../../components/layouts/explore-04/Explore';
import TempCards from '../../components/layouts/home-3/TempCards';
import marketCards from '../../../../assets/tempCard/marketCard';
import { Col, Row } from 'react-bootstrap';

const Market = () => {
    return (
        <div className='auctions'>
            <Header />
            <section className="flat-title-page inner">
                <div className="overlay"></div>
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-title-heading mg-bt-12">
                                <h1 className="heading text-center">Market</h1>
                            </div>
                            <div className="breadcrumbs style2">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="#">Market</Link></li>
                                    <li> All Cards</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section >
                <Row>
                    <Col md={2} style={{height:"200px", fontSize:"25px"}} >
                        <Explore data={widgetSidebarData}/>
                    </Col>
                    <Col md={10} >
                        <TempCards data={marketCards} />
                    </Col>
                </Row>
            </section>

            <Footer />
        </div>
    );
}

export default Market;
