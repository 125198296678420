import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface WalletState {
	address: string | null;
	balance: string | null;
	isConnected: boolean;
}

const initialState: WalletState = {
	address: null,
	balance: null,
	isConnected: false,
};

const walletSlice = createSlice({
	name: "wallet",
	initialState,
	reducers: {
		setWallet(
			state,
			action: PayloadAction<{ address: string; balance: string }>
		) {
			state.address = action.payload.address;
			state.balance = action.payload.balance;
			state.isConnected = true;

			// Save to local storage
			localStorage.setItem("walletAddress", action.payload.address);
			localStorage.setItem("walletBalance", action.payload.balance);
			localStorage.setItem("walletConnected", "true");
		},
		clearWallet(state) {
			state.address = null;
			state.balance = null;
			state.isConnected = false;

			// Remove from local storage
			localStorage.removeItem("walletAddress");
			localStorage.removeItem("walletBalance");
			localStorage.removeItem("walletConnected");
		},
		rehydrateWallet(state) {
			const address = localStorage.getItem("walletAddress");
			const balance = localStorage.getItem("walletBalance");
			const isConnected =
				localStorage.getItem("walletConnected") === "true";

			if (address && balance && isConnected) {
				state.address = address;
				state.balance = balance;
				state.isConnected = isConnected;
			}
		},
	},
});

export const { setWallet, clearWallet, rehydrateWallet } = walletSlice.actions;
export default walletSlice.reducer;
