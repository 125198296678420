import { useState } from "react";
import IWalletPresenter, { Wallet } from "./IWalletPresenter";


export default function WalletPresenter() : IWalletPresenter {
    const [wallets, setWallets] = useState<Wallet[]>([]);

    
    return {
        wallets,
        setWallets
    }
}