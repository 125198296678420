import { useState } from "react";
import LeaderBoardModel from "../../../business/model/Leaderboard/LeaderBoardModel";
import LeaderBoardUserModel from "../../../business/model/Leaderboard/LeaderBoardUserModel";
import ILeaderBoardPresenter from "./ILeaderBoardPresenter";

export default function LeaderBoardPresenter() : ILeaderBoardPresenter {
    const [leaderBoard, setLeaderBoard] = useState<LeaderBoardModel>();
    const [userPlace, setUserPlace] = useState<LeaderBoardUserModel>();

    return {
        leaderBoard,
        setLeaderBoard,
        userPlace,
        setUserPlace,
    }
}