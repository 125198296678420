import LeaderBoardUserDto from "../../../data/dto/LeaderBoard/LeaderBoardUserDto";
import ILeaderBoardGateway from "../../../data/gateway/LeaderBoard/ILeaderBoardGateWay";
import Mapper from "../../../util/mapper/Mapper";
import LeaderBoardModel from "../../model/Leaderboard/LeaderBoardModel";
import LeaderBoardUserModel from "../../model/Leaderboard/LeaderBoardUserModel";
import ILeaderBoardUseCase from "./ILeaderBoardUseCase";

export class LeaderBoardUseCase implements ILeaderBoardUseCase {
    private readonly leaderboardgateway: ILeaderBoardGateway;

    constructor(leaderboardgateway: ILeaderBoardGateway) {
        this.leaderboardgateway = leaderboardgateway;
    }

    public readonly getLeaderBoard = async () => {
        var leaders = await this.leaderboardgateway.getLeaderBoard();
        console.log("leaderboard usecase page", leaders)
        if (leaders != null) {
            var leaderboardModel = Mapper.map<LeaderBoardUserDto[], LeaderBoardModel>(leaders);
            leaderboardModel.users = leaderboardModel.users.sort((a : any, b : any) => (a.totalScore > b.totalScore) ? 1 : -1);
            return leaderboardModel;
        }
    }

    public readonly getUserOrder = async (userId: string) => {
        var userLeaderInfo = await this.leaderboardgateway.getuserOrder(userId);
        var allUsersOrdered = await this.getLeaderBoard();
        if (userLeaderInfo != null && allUsersOrdered != null) {
            var userleaderboardModel = Mapper.map<LeaderBoardUserDto, LeaderBoardUserModel>(userLeaderInfo);            
            var userOrder = allUsersOrdered.users.find((a : any) => a.userId === userId)?.order;
            if (userOrder != null) {
                userleaderboardModel.order = userOrder;
            }   
              return userleaderboardModel;        
        }        
    }

    public readonly dispose = () => {

    }

}