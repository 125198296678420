import { MoralisContextValue } from "react-moralis";
import { Authentication, Status } from "../../../startup/AuthProvider";
import IAuthUseCase from "./IAuthUsecase";

export default class AuthUseCase implements IAuthUseCase {

    // Private Properties
    private moralis?: MoralisContextValue;
    private authContext?: Authentication;

    constructor() {
    }

    public readonly setProps = (moralis: MoralisContextValue, authContext: Authentication) => {
        this.authContext = authContext;
        this.moralis = moralis;
    }

    public readonly login = async () => {
        try {
            const user = await this.moralis?.authenticate({ type: 'sol' });

            if (user == null) {
                throw 'NullUser';
            }

            this.authContext?.setUser(user);
            this.authContext?.setToken(user.getSessionToken());
        }
        catch (error) {
            if (error === 'NullUser') {
                console.error("NullUser: EROORRRR: ", JSON.stringify(this.moralis!));
                this.authContext?.setStatus({ status: Status.Error, message: "Null User" });

            }
            else {
                console.error("ERROORRRR: ", this.moralis);
                this.authContext?.setStatus({ status: Status.Error, message: "TODODOOOOOOOO" }); // TODO
            }
        }
    }

    public readonly logout = async () => {
        await this.moralis?.logout();
        this.authContext?.setUser(undefined);
        this.authContext?.setToken(undefined);
    }

    public readonly dispose = () => {

    }
}