import { useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import popularCollectionData from '../../../../assets/fake-data/data-popular-collection';

interface IData {
    title: string;
    imgAuthor: string;
    name: string;
    imgleft: string;
    imgright1: string;
    imgright2: string;
    imgright3: string;
    imgright4: string;
    imgtop: string;
    wishlist: string;
    count: string;

}

const AllPlayers = () => {
    console.log(popularCollectionData)
    const [data] = useState<IData[] | undefined>(popularCollectionData);

    const [visible, setVisible] = useState(6);
    const showMoreItems = () => {
        setVisible((prevValue) => prevValue + 3);
    }
    return (
        <div className='authors'>
            <Header />
            <section className="flat-title-page inner">
                <div className="overlay"></div>
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-title-heading mg-bt-12">
                                <h1 className="heading text-center">Players</h1>
                            </div>
                            <div className="breadcrumbs style2">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="#">Players</Link></li>
                                    <li>All Players List</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="tf-section our-creater dark-style2">
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="tf-title style4 mg-bt-38">
                                All Players</h2>
                        </div>
                        {
                            // data?.slice(0, visible).map((item, index) => (
                            //     <div key={index} className="col-lg-4 col-md-6 col-12">
                            //         <div className="sc-card-collection style-2">
                            //             <div className="card-bottom">
                            //                 <div className="author">
                            //                     <div className="sc-author-box style-2">
                            //                         <div className="author-avatar">
                            //                             <img src={item.imgAuthor} alt="Axies" className='avatar' />
                            //                             <div className="badge"></div>
                            //                         </div>
                            //                     </div>
                            //                     <div className="content">
                            //                         <Link to="/gamerid">
                            //                             <h4>{item.name}</h4>
                            //                         </Link> {/* TODO: add gamer id */}
                            //                         <div className="infor">
                            //                             <span>{item.count}</span>
                            //                         </div>
                            //                     </div>
                            //                 </div>
                            //             </div>
                            //             <Link to="/gamerid">   
                            //                 <div className="media-images-collection">
                            //                     <div className="box-left">
                            //                         <img src={item.imgleft} alt="Axies" />
                            //                     </div>
                            //                     <div className="box-right">
                            //                         <div className="top-img">
                            //                             <img src={item.imgright1} alt="Axies" />
                            //                             <img src={item.imgright2} alt="Axies" />
                            //                         </div>
                            //                         <div className="bottom-img">
                            //                             <img src={item.imgright3} alt="Axies" />
                            //                         </div>
                            //                     </div>
                            //                 </div>
                            //             </Link> {/* TODO: add gamer id */}
                            //         </div>
                            //     </div>
                            // ))
                        }
                        {
                            // visible < (data != null && data.length &&
                            //     <div className="col-md-12 wrap-inner load-more text-center">
                            //         <Link to="#" id="load-more" className="sc-button loadmore fl-button pri-3" onClick={showMoreItems}><span>Load More</span></Link>
                            //     </div>)
                        }
                    </div>
                </div>
            </section>
            <section className="tf-section our-creater dark-style2">
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="tf-title style4 mg-bt-38">
                                New Players</h2>
                        </div>
                        {
                            // data?.slice(0, visible).map((item, index) => (
                            //     <div key={index} className="col-lg-4 col-md-6 col-12">
                            //         <div className="sc-card-collection style-2">
                            //             <div className="card-bottom">
                            //                 <div className="author">
                            //                     <div className="sc-author-box style-2">
                            //                         <div className="author-avatar">
                            //                             <img src={item.imgAuthor} alt="Axies" className='avatar' />
                            //                             <div className="badge"></div>
                            //                         </div>
                            //                     </div>
                            //                     <div className="content">
                            //                         <Link to="/gamerid">
                            //                             <h4>{item.name}</h4>
                            //                         </Link> {/* TODO: add gamer id */}
                            //                         <div className="infor">
                            //                             <span>{item.count}</span>
                            //                         </div>
                            //                     </div>
                            //                 </div>
                            //             </div>
                            //             <Link to="/gamerid">   
                            //                 <div className="media-images-collection">
                            //                     <div className="box-left">
                            //                         <img src={item.imgleft} alt="Axies" />
                            //                     </div>
                            //                     <div className="box-right">
                            //                         <div className="top-img">
                            //                             <img src={item.imgright1} alt="Axies" />
                            //                             <img src={item.imgright2} alt="Axies" />
                            //                         </div>
                            //                         <div className="bottom-img">
                            //                             <img src={item.imgright3} alt="Axies" />
                            //                         </div>
                            //                     </div>
                            //                 </div>
                            //             </Link>  {/* TODO: add gamer id */}
                            //         </div>
                            //     </div>
                            // ))
                        }
                        {
                            // visible < (data != null && data.length &&
                            //     <div className="col-md-12 wrap-inner load-more text-center">
                            //         <Link to="#" id="load-more" className="sc-button loadmore fl-button pri-3" onClick={showMoreItems}><span>Load More</span></Link>
                            //     </div>)
                        }
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}

export default AllPlayers;
