import ILeaderBoardUseCase from "../../../business/usecase/LeaderBoard/ILeaderBoardUseCase";
import ILeaderBoardPresenter from "../../presenter/LeaderBoard/ILeaderBoardPresenter";
import ILeaderBoardInteractor from "./ILeaderBoardInteractor";

export default class LeaderBoardInteractor implements ILeaderBoardInteractor {

    private leaderBoardPresenter?: ILeaderBoardPresenter;
    private readonly leaderboardUsecase: ILeaderBoardUseCase;

    constructor(leaderboardUsecase: ILeaderBoardUseCase,) {
        this.leaderboardUsecase = leaderboardUsecase;
    }


    public readonly initialize = (LeaderBoardPresenter: ILeaderBoardPresenter) => {
        this.leaderBoardPresenter = LeaderBoardPresenter;
    };

    public readonly leaderboard = async () => {
        try {
            const allLeaderboard = await this.leaderboardUsecase.getLeaderBoard();

            console.log("here is interactor leader board: ", allLeaderboard);
            if (allLeaderboard != null) {
                this.leaderBoardPresenter?.setLeaderBoard(allLeaderboard);
            }
        }
        catch (error) {

        }
    }

    public readonly userOrder = async (userId: string) => {
        try {
            const userLeaderboard = await this.leaderboardUsecase.getUserOrder(userId);
            if (userLeaderboard != null) {
                this.leaderBoardPresenter?.setUserPlace(userLeaderboard);
            }

        } catch (error) {

        }

    }


    public readonly dispose = () => {

    }

}