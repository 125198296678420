
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import { Link } from 'react-router-dom';
import AllLeaderBoard from '../../components/leaderboard/AllLeaderBoard/allLeaderBoard';
import { useEffect, useRef, useState } from 'react';
import { DIProvider } from '../../../../startup/DependencyProvider';
import ILeaderBoardInteractor from '../../../interactor/LeaderBoard/ILeaderBoardInteractor';

const LeaderBoard = () => {
    const [, forceUpdate] = useState<any>();

    const presenter = DIProvider.leaderBoardPresenter();
    const interactoRef = useRef<ILeaderBoardInteractor | undefined>();
    const interactor = interactoRef.current;
    console.log("leaderboard interactor", interactor)

    useEffect(() => {
        const interactor = DIProvider.leaderBoardInteractor();
        interactoRef.current = interactor;
        interactor.initialize(presenter);
        forceUpdate({});

        interactor.leaderboard();
  
        return () => {
            console.log("dispose");
            interactor?.dispose();
            console.log("leaderboard home page")
            console.log("presenter", presenter)
        }
    }, []);



    return (
        <div className='home-3'>
            <Header />
            <section className="flat-title-page inner">
                <div className="overlay"></div>
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-title-heading mg-bt-12">
                                <h1 className="heading text-center">Players</h1>
                            </div>
                            <div className="breadcrumbs style2">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="#">Players</Link></li>
                                    <li>Leader Board</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            <AllLeaderBoard leaderboard={presenter.leaderBoard}/>

            <Footer />
        </div>
    );
}

export default LeaderBoard;
