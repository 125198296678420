import { useContext, createContext, useEffect } from "react";
import AuthContext, { DefaultAuthValues } from "./AuthProvider";
import LeaderBoardPresenter from "../presentation/presenter/LeaderBoard/LeaderBoardPresenter";
import ILeaderBoardInteractor from "../presentation/interactor/LeaderBoard/ILeaderBoardInteractor";
import LeaderBoardInteractor from "../presentation/interactor/LeaderBoard/LeaderBoardInteractor";
import ILeaderBoardUseCase from "../business/usecase/LeaderBoard/ILeaderBoardUseCase";
import { LeaderBoardUseCase } from "../business/usecase/LeaderBoard/LeaderBoardUseCase";
import ILeaderBoardGateway from "../data/gateway/LeaderBoard/ILeaderBoardGateWay";
import LeaderBoardGateway from "../data/gateway/LeaderBoard/LeaderBoardGateWay";
import WalletPresenter from "../presentation/presenter/Wallet/WalletPresenter";
import IAppInteractor from "../presentation/interactor/Wallet/IAppInteractor";
import AppInteractor from "../presentation/interactor/Wallet/AppInteractor";
import IAuthUseCase from "../business/usecase/auth/IAuthUsecase";
import AuthUseCase from "../business/usecase/auth/AuthUsecase";
import { useMoralis } from "react-moralis";

const singletons: {[key: string]: any;} = {};

function getSingleton<T>(name: string, createFunction: () => T) : T {
  if (singletons[name] == null) { 
    singletons[name] = createFunction();
  }
  return singletons[name];
}

export const DIProvider = {
  authContext : DefaultAuthValues,


    // View
    // Presenter
    walletPresenter : WalletPresenter,
    leaderBoardPresenter : LeaderBoardPresenter,


    // Interactor
    AppInteractor : () : IAppInteractor => new AppInteractor(DIProvider.authUseCase()),

    leaderBoardInteractor : () : ILeaderBoardInteractor => new LeaderBoardInteractor(DIProvider.leaderBoardUseCase()),


    // Business
    // Usecase
    authUseCase : () : IAuthUseCase => getSingleton("AuthUseCase", () => new AuthUseCase()),
    leaderBoardUseCase : () : ILeaderBoardUseCase => new LeaderBoardUseCase(DIProvider.leaderBoardGateway()),

    // Data
    // GateWay
    leaderBoardGateway : () : ILeaderBoardGateway => new LeaderBoardGateway(),
  }

type Props = {
  children: React.ReactNode,
};

export const DependencyContext = createContext(DIProvider);

export function DependencyProvider(props : Props) {
  const authContext = useContext(AuthContext);

  DIProvider.authContext = authContext;

  const moralis = useMoralis();

  useEffect(() => {
    DIProvider.authUseCase().setProps(moralis, authContext);
  }, [moralis, authContext])


  
  return (
    <DependencyContext.Provider value={DIProvider}>
        {props.children}
    </DependencyContext.Provider>
  )
}