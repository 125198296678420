export default class Config {
   
}


export const dbConnect = {
    url : "https://gi9gfnbmznup.usemoralis.com:2053/server/classes/"
} 

export const dbBody = {
    body : {
        "where": {},
        "_method": "GET",
        "_ApplicationId": "bKqt2K46X8pWXLonA7nAwR8OzUxlkkmJ4J1EDxuj",
        "_ClientVersion": "js3.4.1",
        "_MasterKey": "r2IlJNwdAdFG0wOCSt8YIgADnsBg1M8At4Dp1AXT",
        "_InstallationId": "0d51fc85-2929-4cda-9c6b-06362267bd84"
    }
}