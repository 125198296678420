import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import heroSliderData from '../../../../assets/fake-data/data-slider-3';
import LiveAuction from '../../components/layouts/home-3/LiveAuction';
import TopSeller from '../../components/layouts/home-3/TopSeller';
import Slider from '../../components/slider/Slider';
import topSellerData from '../../../../assets/fake-data/data-top-seller';
import TodayPicks from '../../components/layouts/home-3/TodayPicks';
import todayPickData from '../../../../assets/fake-data/data-today-pick';
import marketCards from '../../../../assets/tempCard/marketCard';
import { useContext } from 'react';
import AuthContext, { Status } from '../../../../startup/AuthProvider';

const Home = () => {
   const {status} =  useContext(AuthContext);

   if (status.status == Status.Authenticated ) {
    console.log("authentasfkljsakf");
   }

    return (
        <div className='home-3'>
            { status.status == Status.Authenticated &&
                <Header />
            }
            <Header />
            <Slider data={heroSliderData} />
            {/* <LiveAuction data={liveAuctionData} />
            <TopSeller data={topSellerData} /> */}
            <TodayPicks data={marketCards} />
            <Footer />
        </div>
    );
}

export default Home;
