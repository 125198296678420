import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { RootState } from "../../../../store";
import menus from "../../pages/menu/menu";
import DarkMode from "./DarkMode";
import galactoonNBg from "../../../../assets/images/icon/galactoonNoBackground.png";
import { clearWallet, rehydrateWallet } from "../../../../store/walletSlice";

const walletIcon = "/assets/icon/cryptowallet.png";

const dropdownStyle: React.CSSProperties = {
  backgroundColor: "#ffffff",
  color: "#292b2c", 
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
  borderRadius: "8px",
  padding: "10px 20px",
  fontSize: "16px",
  minWidth: "100px",
  zIndex: 1000,
  position: "absolute",
  top: "50px",
  right: "0",
};

const Header = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const { address: walletAddress, balance, isConnected } = useSelector(
    (state: RootState) => state.wallet
  );

  const [dropdownVisible, setDropdownVisible] = useState(false);

  const handleLogout = () => {
    dispatch(clearWallet());
    setDropdownVisible(false);
    console.log("Wallet disconnected");
  };

  const headerRef = useRef<HTMLElement | null>(null);
  const menuLeft = useRef<HTMLDivElement | null>(null);
  const btnToggle = useRef<HTMLDivElement | null>(null);

  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  useEffect(() => {
    // Rehydrate the wallet state from local storage
    dispatch(rehydrateWallet());

    const isSticky = () => {
      const header = headerRef.current;
      const scrollTop = window.scrollY;
      if (header) {
        scrollTop >= 300 ? header.classList.add("is-fixed") : header.classList.remove("is-fixed");
        scrollTop >= 400 ? header.classList.add("is-small") : header.classList.remove("is-small");
      }
    };

    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, [dispatch]);

  const menuToggle = () => {
    menuLeft.current?.classList.toggle("active");
    btnToggle.current?.classList.toggle("active");
  };

  const handleOnClick = (index: number) => setActiveIndex(index);

  return (
    <header id="header_main" className="header_1 js-header" ref={headerRef}>
      <div className="themesflat-container">
        <div className="row">
          <div className="col-md-12">
            <div id="site-header-inner">
              <div className="wrap-box flex">
                {/* Site Logo */}
                <div id="site-logo" className="clearfix">
                  <div id="site-logo-inner">
                    <Link to="/" rel="home" className="main-logo">
                      <img
                        className="logo-dark"
                        id="logo_header"
                        src={galactoonNBg}
                        alt="Galactoon"
                      />
                      <img
                        className="logo-light"
                        id="logo_header"
                        src={galactoonNBg}
                        alt="Galactoon"
                      />
                    </Link>
                  </div>
                </div>

                {/* Mobile Menu Button */}
                <div className="mobile-button" ref={btnToggle} onClick={menuToggle}>
                  <span></span>
                </div>

                {/* Navigation Menu */}
                <nav id="main-nav" className="main-nav" ref={menuLeft}>
                  <ul id="menu-primary-menu" className="menu">
                    {menus.map((data: any, index) => (
                      <li
                        key={index}
                        onClick={() => handleOnClick(index)}
                        className={`menu-item ${data.namesub ? "menu-item-has-children" : ""} ${
                          activeIndex === index ? "active" : ""
                        }`}
                      >
                        <Link to={data.links}>{data.name}</Link>
                        {data.namesub && (
                          <ul className="sub-menu">
                            {data.namesub.map((submenu: any) => (
                              <li
                                key={submenu.id}
                                className={`menu-item ${pathname === submenu.links ? "current-item" : ""}`}
                              >
                                <Link to={submenu.links}>{submenu.sub}</Link>
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
                    ))}
                  </ul>
                </nav>

                {/* Wallet Section */}
                <div className="flat-search-btn flex">
                  {isConnected ? (
                    <div className="wallet-info" style={{ position: "relative" }}>
                      {/* Wallet Icon Button */}
                      <img
                        src={walletIcon}
                        alt="Wallet Icon"
                        style={{ cursor: "pointer", width: "45px" }}
                        onClick={() => setDropdownVisible(!dropdownVisible)}
                      />

                      {/* Wallet Dropdown */}
                      {dropdownVisible && (
                        <div className={"card"} style={dropdownStyle}>
                          <div style={{ marginBottom: "10px" }}>
                            <strong>Address:</strong> {walletAddress}
                          </div>
                          <div style={{ marginBottom: "10px" }}>
                            <strong>Balance:</strong> {balance} ETH
                          </div>
                          <button className="btn btn-danger" onClick={handleLogout} style={{ width: "100%", fontSize: "16px" }}>
                            Logout
                          </button>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="sc-btn-top mg-r-12" id="site-header">
                      <Link to="/wallet-connect" className="sc-button header-slider style style-1 wallet fl-button pri-1">
                        <span>Login with Wallet Connect</span>
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DarkMode />
    </header>
  );
};

export default Header;
