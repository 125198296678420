import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Tab, Tabs, TabList } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import widgetSidebarData from '../../../../assets/fake-data/data-widget-sidebar';
import Explore from '../../components/layouts/explore-04/Explore';
import marketCards from '../../../../assets/tempCard/marketCard';
import moralabs from '../../../../assets/images/avatar/moralabs.png';

import TempCards from '../../components/layouts/home-3/TempCards';
import { Col, Row } from 'react-bootstrap';


const Profile = () => {
    const [menuTab] = useState(
        [
            {
                class: 'active',
                name: 'ALL'
            },
            {
                class: '',
                name: 'On Sale'
            },
        ]
    )

    const data = useState(marketCards)


    const [visible, setVisible] = useState(8);
    const showMoreItems = () => {
        setVisible((prevValue : any) => prevValue + 4);
    }


    return (
        <div className='authors-2'>
            <Header />
            <section className="flat-title-page inner" >
                <div className="overlay"></div>
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-title-heading mg-bt-12">
                                <h1 className="heading text-center">Player Profile</h1>
                            </div>
                            <div className="breadcrumbs style2">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="#">Pages</Link></li>
                                    <li>Player</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="tf-section authors">
                <div className="themesflat-container">
                    <div className="flat-tabs tab-authors">
                        <div className="author-profile flex" style={{ marginBottom: 30 }}>
                            <div className="feature-profile">
                                <img src={moralabs} className="avatar" />
                            </div>
                            <div className="infor-profile">
                                <span>Player Profile</span>
                                <h2 className="title">Moralabs</h2>
                                <p className="content"></p>
                                <br />
                                <br />
                                <br />
                                <br /><br />
                            </div>
                            <div className="widget-social style-3">
                                <div className="btn-profile"><Link to="/editprofile" className="sc-button style-1 follow">Edit Profile</Link></div>
                            </div>
                        </div>
                        <Tabs>
                            <TabList>
                                {
                                    menuTab.map((item : any, index : number) => (
                                        <Tab key={index}>{item.name}</Tab>
                                    ))
                                }
                            </TabList>

                            <div className="content-tab">
                                <div className="content-inner">
                                    <div className="row">
                                        <div className="col-xs-1" >
                                            <Row>
                                                <Col md={2}>
                                                    <Explore data={widgetSidebarData} />
                                                </Col>
                                                <Col md={10} >
                                                    <TempCards data={marketCards} />
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tabs>
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    );
}

export default Profile;
