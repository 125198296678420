
import Home from "./home/index";
import Explore from "./exploreCards/index";
import AllPlayers from "./players/allPlayers";
import Profile from "./profile/profile";
import EditProfile from "./profile/editProfile";
import WalletConnect from "./walletConnect/walletConnect";
import ContactUs from "./contact/contactUs";
import FAQ from "./faq/faq";
import ItemDetails from "./itemDetails/itemdetails";
//import NoResult from "./noResult/noResult";
import LootBox from "./lootBox/lootBox";
import Market from "./market/market";
import LeaderBoard from "./leaderBoard";
//import MetamaskConnection from "./walletConnect/metamaskConnection";


const routes = [
  { path: '/', component: <Home /> },
  { path: '/explorecards', component: <Explore /> },
  { path: '/allplayers', component: <AllPlayers /> },
  { path: '/profile', component: <Profile /> },
  { path: '/editprofile', component: <EditProfile /> },
  { path: '/wallet-connect', component: <WalletConnect /> },
  { path: '/contactus', component: <ContactUs /> },
  { path: '/faq', component: <FAQ /> },
  { path: '/itemdetails', component: <ItemDetails /> },
  //{ path: '/noresult', component: <NoResult /> },
  { path: '/lootbox', component: <LootBox /> },
  { path: '/market', component: <Market /> },
  { path: '/leaderboard', component: <LeaderBoard /> },
  //{ path: '/metamask', component: <MetamaskConnection /> },
]

export default routes;