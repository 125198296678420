import { MoralisContextValue } from "react-moralis";
import IAuthUseCase from "../../../business/usecase/auth/IAuthUsecase";
import IWalletPresenter from "../../presenter/Wallet/IWalletPresenter";
import IAppInteractor from "./IAppInteractor";

export default class AppInteractor implements IAppInteractor {
    private readonly authUseCase: IAuthUseCase;
    private walletPresenter ?: IWalletPresenter;
    private moralis?: MoralisContextValue;

    constructor (authUseCase: IAuthUseCase) {
        this.authUseCase = authUseCase;
    }

    public readonly initialize = (WalletPresenter : IWalletPresenter, moralis: MoralisContextValue) => {
        this.walletPresenter = WalletPresenter;
        this.moralis = moralis;
    }

    public readonly login = () => {
        this.authUseCase.login();

    }

    public readonly logout = () => {
        this.authUseCase.logout();

    }

    public readonly dispose = () =>  {

    }
}