import ILeaderBoardGateway from "./ILeaderBoardGateWay";
import { dbConnect } from "../../util/dbConstants";
import LeaderBoardUserDto from "../../dto/LeaderBoard/LeaderBoardUserDto";

export default class LeaderBoardGateway implements ILeaderBoardGateway {
    //     constructor() {
    //     }

    public readonly getLeaderBoard = async () => {
        try {
            var body = {
                "where": {},
                "_method": "GET",
                "_ApplicationId": "bKqt2K46X8pWXLonA7nAwR8OzUxlkkmJ4J1EDxuj",
                "_ClientVersion": "js3.4.1",
                "_MasterKey": "r2IlJNwdAdFG0wOCSt8YIgADnsBg1M8At4Dp1AXT",
                "_InstallationId": "0d51fc85-2929-4cda-9c6b-06362267bd84"
            }
            var response = await fetch(dbConnect.url + "LeaderBoard", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    //'Authorization' : 'Bearer ' + localStorage.getItem("token") 
                },
                body: JSON.stringify(body)
            });

            var result: LeaderBoardUserDto[] = await response.json();

            console.log("all users leaderboard = ", result);

            return result;

        } catch (error) {
            console.log("error: ", error);
        }
    }

    public readonly getuserOrder = async (userId: string) => {
        try {
            var body = {
                "where": { "userId": userId, },
                "_method": "POST",
                "_ApplicationId": "bKqt2K46X8pWXLonA7nAwR8OzUxlkkmJ4J1EDxuj",
                "_ClientVersion": "js3.4.1",
                "_MasterKey": "r2IlJNwdAdFG0wOCSt8YIgADnsBg1M8At4Dp1AXT",
                "_InstallationId": "0d51fc85-2929-4cda-9c6b-06362267bd84"
            }
            var response = await fetch(dbConnect.url + "LeaderBoard", {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    //'Authorization' : 'Bearer ' + localStorage.getItem("token") 
                },
                body: JSON.stringify(body)
            });

            var result: LeaderBoardUserDto = await response.json();
            console.log("user order with userId at LeaderBoard= ", result);

            return result;

        } catch (error) {
            console.log("error: ", error);
        }
    }

    public readonly dispose = () => {

    }

}